import './darkmode';

const topImageArr = [
	["/fs/img/random_hero_images/girl-495923_1920.jpg", "Photo: <a href='https://pixabay.com/users/mintchipdesigns-522107/'>mintchipdesigns</a>"],
	["/fs/img/random_hero_images/cape-verde-320790_1280.jpg", "Image by <a href='https://pixabay.com/users/kikatani-35407/'>Kirsi Kataniemi</a>"],
	["/fs/img/random_hero_images/freedom-307791_1280.png", "Image: <a href='https://pixabay.com/users/clker-free-vector-images-3736/'>Clker-Free-Vector-Images</a>"],
	["/fs/img/random_hero_images/girl-495923_1920.jpg", "Photo: <a href='https://pixabay.com/users/mintchipdesigns-522107/'>mintchipdesigns</a>"],
	["/fs/img/random_hero_images/girl-5305455_1920.jpg", "Photo: <a href='https://pixabay.com/users/kellepics-4893063/'>KELLEPICS</a>"],
	["/fs/img/random_hero_images/people-821624_1920.jpg", "Photo: <a href='https://pixabay.com/users/fancycrave1-1115284/'>fancycrave1</a>"],
	["/fs/img/random_hero_images/pexels-luca-nardone-3651824.jpg", "Image by <a href='https://www.lucanardone.com/''>luca nardone</a>"],
	["/fs/img/random_hero_images/pexels-michael-3287160.jpg", "Image by <a href='https://www.pexels.com/@mgblr'>Michael</a>"],
	["/fs/img/random_hero_images/pexels-mike-191756.jpg", "Image by <a href='https://www.pexels.com/@mikebirdy'>Mike</a>"],
	["/fs/img/random_hero_images/pexels-offsyz-3153353.jpg", "Image by <a href='https://www.pexels.com/@offsyz-1650775'>Shadab</a>"],
	["/fs/img/random_hero_images/pexels-artem-beliaikin-452738.jpg", "Photo by <a href='https://www.pexels.com/@belart84'>Artem Beliaikin</a> from Pexels"],
	["/fs/img/random_hero_images/pexels-otra-ruta-5943844.jpg", "Photo by <a href='https://www.pexels.com/@otra-ruta-3689668'>Otra ruta</a> from Pexels"],
	["/fs/img/random_hero_images/pexels-german-tr-1799881.jpg", "Photo by <a href='https://www.pexels.com/@germantalonr'>Germán TR</a> from Pexels"],
	["/fs/img/random_hero_images/pexels-andre-estevez-3309869.jpg", "Photo by <a href='https://www.pexels.com/@andre-estevez-1743712'>Andre Estevez</a> from Pexels"],
	["/fs/img/random_hero_images/pexels-gabby-k-5063317.jpg", "Photo by <a href='https://www.pexels.com/@gabby-k'>Gabby K</a> from Pexels"],
	["/fs/img/random_hero_images/lilisart-seal.png", "Image by <a href='https://www.lilisart.de/'>Lilisart</a>"],
	["/fs/img/random_hero_images/pexels-pixabay-302271.jpg", "Photo by <a href='https://www.pexels.com/photo/adventure-conifer-daylight-desert-302271/'>Pixabay on Pexels</a>"],
	["/fs/img/random_hero_images/pexels-marctutorials-870170.jpg", "Photo by <a href='https://www.pexels.com/photo/three-boy-s-jumping-into-the-water-870170/'>MarcTutorials</a>"],
	['/fs/img/random_hero_images/robert-collins-tvc5imO5pXk-unsplash.jpg', "Photo by <a href='https://unsplash.com/@robbie36'>Robert Collins</a>"],
	['/fs/img/random_hero_images/jose-casado-Cexvg8htAhs-unsplash_233.png', "Photo by <a href='https://unsplash.com/@josecasado'>Jose Casado</a>"],
	['/fs/img/random_hero_images/jens-johnsson-bGzAOexEZPM-unsplash.jpg', "<a href='https://unsplash.com/photos/bGzAOexEZPM' target='_blank'>Photo</a> by <a href='https://unsplash.com/@jens_johnsson'>Jens Johnsson</a>"]
  ];

function heroImage() {
	let tiCount = topImageArr.length;
	let tiIndex = Math.floor(Math.random()*tiCount);
	let tiImage = topImageArr[tiIndex][0];
	let tiCredid= topImageArr[tiIndex][1];
	if(document.getElementById("topimage")){
		document.getElementById("topimage").src=tiImage;
		document.getElementById("topimage").alt="random image";
	}
	if (document.getElementById("topcredit")) {
		document.getElementById("topcredit").innerHTML=tiCredid;
	}
	return false;
}
window.heroImage = heroImage;

var typeWriterElement = document.getElementById('js-typewriter');
if(typeWriterElement) {
    var typeWriterText    = typeWriterElement.innerHTML;
    typeWriterElement.innerHTML = '';
	var typeWriterCounter = 0;
	var typeWriterSpeed = 20;
}
function typeWriterWrite() {
    if (typeWriterElement && typeWriterCounter < typeWriterText.length) {
        typeWriterElement.innerHTML += typeWriterText.charAt(typeWriterCounter);
        typeWriterCounter++;
        setTimeout(typeWriterWrite, typeWriterSpeed);
    }
}

Date.prototype.getWeek = function () {
	var target  = new Date(this.valueOf());
	var dayNr   = (this.getDay() + 6) % 7;
	target.setDate(target.getDate() - dayNr + 3);
	var firstThursday = target.valueOf();
	target.setMonth(0, 1);
	if (target.getDay() != 4) {
		target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
	}
	return 1 + Math.ceil((firstThursday - target) / 604800000);
}

function getCurrentWeekNumber() {
	// for all elements with class .current_week_number
	let d = new Date();
	let w = d.getWeek();
	let cw_list = document.getElementsByClassName("current_week_number");
	let cw_idx;
	for (cw_idx = 0; cw_idx < cw_list.length; cw_idx++) {
		cw_list[cw_idx].innerText = w;
	}
}

function activate_player_placeholders() {
	let player_placeholder_list = document.getElementsByClassName("sow-iframe-container");
	for (let this_index = 0; this_index < player_placeholder_list.length; this_index++) {
		let this_element = player_placeholder_list[this_index]
		let framecontent = this_element.dataset.framecontent;
		if(framecontent) {
			this_element.addEventListener("click", function() {
				this_element.innerHTML = framecontent;
			});
		}
	}
}

function load_iframe(iframe_id) {
	let target_div = document.getElementById(iframe_id);
	let iframe_markup = iframe_list[iframe_id];
	if(target_div && iframe_markup) {
		target_div.innerHTML = iframe_markup;
	}
}

function load_mantra(elementId) {
	let target_div = document.getElementById(elementId);
	if(target_div) {
		var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    	xobj.open('GET', '/cfg/mantra.json', true);
    	xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            let mantraData = xobj.responseText;
			console.log(JSON.stringify(mantraData, null));
          }
    	};
	    xobj.send(null);
    	console.log(xobj);
		let selector = 1;
		let quote  = '';
		let author = '';
		let text = quote + ' - ' + author;
		typewriterDisplay(text, 'mantra');
	}
}

function typewriterDisplay(myText, myTarget, myTiming) {
	if(myText && myTarget) {
		console.log(myText);
		console.log(myTarget);
		console.log(myTiming);
	}
}

function setCookieDays(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Strict";
}

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
	  let c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
  }

function load_cookieconsentlayer() {
	let cStatus = getCookie("cookieconsent");
	if(cStatus !== 'confirm') {
		let cConte = 'We use cookies to ensure the functionality of our website, personalize content and advertising and to analyze our traffic. Also we include third party content that uses cookies. For more Information see our <a href="/privacy/" style="color:yellow;">privacy policy page</a>.<br><button id="cookieconsentlayerbutton" style="color:white;background-color:red;">Got it</button>';
		let notice = document.createElement('div'),
		noticeStyle = notice.style;
		notice.innerHTML = cConte;
		notice.setAttribute('id', 'cookieconsentlayer');
		noticeStyle.position = 'absolute';
		noticeStyle.bottom = 0;
		noticeStyle.left = 0;
		noticeStyle.right = 0;
		noticeStyle.background = 'maroon';
		noticeStyle.color = 'white';
		noticeStyle["z-index"] = '9999';
		noticeStyle.padding = '20px';
		noticeStyle["text-align"] = 'center';
		noticeStyle["font-size"] = "14px";
		noticeStyle["line-height"] = "28px";
		noticeStyle.fontFamily = 'Arial, sans-serif';
		document.body.appendChild(notice);
	}
	let cLayer = document.getElementById('cookieconsentlayer');
	let cButto = document.getElementById('cookieconsentlayerbutton');
	if(cLayer && cButto) {
		document.getElementById('cookieconsentlayerbutton').addEventListener("click", function(){
			cLayer.style.display = 'none';
			setCookieDays('cookieconsent', 'confirm', 100);
      location.reload();
		});
	}
}

document.addEventListener("DOMContentLoaded", function(event) {
	window.recover_darkmode();
	heroImage();
	getCurrentWeekNumber();
	typeWriterWrite();
	activate_player_placeholders();
	load_mantra('mantra');
	load_cookieconsentlayer();
});
