window.set_darkmode = function (dark_string) {
	if(dark_string === 'activated') {
		document.getElementsByTagName("html")[0].classList.remove("lightmode");
		document.getElementsByTagName("body")[0].classList.remove("lightmode");
		document.getElementsByTagName("html")[0].classList.add("darkmode");
		document.getElementsByTagName("body")[0].classList.add("darkmode");
		localStorage.setItem('darkmode', 'activated');
	}
	else if(dark_string === 'none') {
		document.getElementsByTagName("html")[0].classList.remove("darkmode");
		document.getElementsByTagName("body")[0].classList.remove("darkmode");
		document.getElementsByTagName("html")[0].classList.add("lightmode");
		document.getElementsByTagName("body")[0].classList.add("lightmode");
		localStorage.setItem('darkmode', 'none');
	}
	else {
		// not yet
	}
}

window.recover_darkmode = function () {
	if(localStorage.getItem('darkmode') === 'activated') {
		set_darkmode('activated');
	}
}
